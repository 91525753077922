<script setup>
    import { useStore } from 'vuex';
    import { computed } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    const store = useStore();
    const positions = computed(() => store.state.user.sidebarInfo.positions);
    const defaultCss = 'alert alert-arrow-left alert-icon-left alert-dismissible mb-0 shadow-sm';
</script>

<template>
    <div class='position-limit-alert-container'>
        <div :class="[defaultCss, 'alert-light-warning']"
             v-if='(positions && positions.percentage >= 98 && positions.percentage < 100)'>
            <font-awesome-icon icon='fa-regular fa-bell' />
            Seu limite de cargos está quase no fim, mas não se preocupe! Entre em contato com nossa equipe agora mesmo
            para adquirir mais créditos ou fazer um upgrade no seu plano e continuar aproveitando nossos serviços.
        </div>

        <div :class="[defaultCss, 'alert-light-danger']" v-else-if='positions && positions.percentage >= 100'>
            <font-awesome-icon icon='fa-regular fa-bell' />
            Seu limite de cargos foi atingido, mas não se preocupe! Entre em contato com nossa equipe agora mesmo para
            fazer um upgrade no seu plano.
        </div>
    </div>
</template>
<style scoped>
    .position-limit-alert-container {
        padding: 1.2rem 1.2rem 0;
    }
</style>
