<template>
    <div class='border-dashed mt-2 border-1 p-3 row m-3 shadow-sm'>
        <div class='col-sm-3'>
            <text-input
                type='date'
                label='Data Inicio'
                v-model='formFilter.start_date'
            />
        </div>
        <div class='col-sm-3'>
            <text-input
                type='date'
                label='Data Fim'
                v-model='formFilter.end_date'
            />
        </div>
        <div class='col-sm-4'>
            <select-input
                label='Setor'
                v-model='formFilter.sector_id'
                :options='sectors'
                placeholder='Pesquise...' />
        </div>
        <div class='col-sm-2'>
            <app-button variant='success' icon='fa fa-search' text='Pesquisar' class='w-100'
                        style='margin-top: 33px' @click.prevent='onFormFilter' />
        </div>
    </div>
</template>

<script setup>
    import SelectInput from '@/components/SelectInput.vue';
    import AppButton from '@/components/AppButton.vue';
    import { computed, ref } from 'vue';
    import TextInput from '@/components/TextInput.vue';
    import moment from 'moment';

    const formFilter = ref({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        sector_id: null
    });

    const emit = defineEmits(['onFilter']);
    const props = defineProps({
        sectors: { required: true }
    });

    const onFormFilter = () => {
        emit('onFilter', filters.value);
    };

    const filters = computed(() => {
        let endpoint = ``;
        if (formFilter.value.start_date) {
            endpoint += `&start_date=${formFilter.value.start_date}`;
        }
        
        if (formFilter.value.end_date) {
            endpoint += `&end_date=${formFilter.value.end_date}`;
        }

        if (formFilter.value.sector_id) {
            endpoint += `&sector_id=${formFilter.value.sector_id}`;
        }

        return endpoint;
    });
</script>

<style scoped>

</style>
