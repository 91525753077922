<template>
    <div class='form-group'>
        <label v-if='label'>{{ label }} <span v-if='required' class='text-danger'>*</span> </label>
        <input :type='type'
               :class='["form-control", {"is-invalid" : errors && errors.length}]'
               :placeholder='placeholder'
               :value='modelValue'
               :disabled='disabled'
               :required='required'
               :autocomplete='autocomplete'
               @change='$emit("onChange", $event.target.value)'
               @input='updateValue'
               :step="step"
               :min="min"
               :max="max"
        >

        <div v-if='errors && errors.length' class='invalid-feedback' v-for='(error, index) in errors'
             :key='`invalid-feedback-${index}`'> {{ error }}
        </div>
    </div>
</template>

<script setup>
    const emit = defineEmits(['update:modelValue']);

    const props = defineProps({
        label: { type: String, default: null },
        placeholder: { type: String, default: '' },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        autocomplete: { type: String, default: '' },
        errors: { default: null },
        modelValue: { type: [String, Number] },
        type: { type: String, default: 'text' },
        step: String,
        min: String,
        max: String,
    });

    const updateValue = (event) => {
        emit('update:modelValue', event.target.value);
    };
</script>
