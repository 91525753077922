<template>
    <div class='form-group'>
        <div class='d-flex justify-content-between align-items-center'>
            <label v-if='label'>
                {{ label }} <span v-if='required' class='text-danger'>*</span>
            </label>

            <small v-if='onCreate'
                class='fw-bold outline-badge-info badge cursor-pointer' @click.prevent='onCreate()'>
                <font-awesome-icon icon='fa-solid fa-plus' />
                cadastrar
            </small>
        </div>

        <vue-multiselect
            :placeholder='placeholder'
            selected-label='Selecionado'
            select-label=''
            deselect-label='Remover seleção'
            :label='optionLabel'
            v-model='valueSelect'
            :value='modelValue'
            :track-by='optionValue'
            @select='updateValue'
            @remove='onRemove'
            @search-change='onSearch'
            :custom-label='customLabel'
            :loading='loading'
            :disabled='disabled'
            :options='options'
        >

            <template #option='props' v-if='subItem'>
                {{ props.option[optionLabel] }} <br>
                <small style='font-weight: bold'>{{ subItem(props.option) }}</small>
            </template>

            <template #noOptions>
                Listagem vazia
            </template>

            <template #noResult>
                Nenhum registro encontrado
            </template>
        </vue-multiselect>

        <div class='typo__label form__label mt-1' v-if='errors && errors.length'
             v-for='(error, index) in errors' :key='`invalid-feedback-${index}`'>
            <span class='error-select2'>{{ error }}</span>
        </div>


    </div>
</template>

<script setup>
    import VueMultiselect from 'vue-multiselect';
    import { ref, onUpdated, onMounted } from 'vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    onMounted(() => {
        valueSelect.value = props.options.find(option => option[props.optionValue] == props.modelValue);
    });

    onUpdated(() => {
        valueSelect.value = props.options.find(option => option[props.optionValue] == props.modelValue);
    });

    const emit = defineEmits(['update:modelValue', 'onChange', 'onSearch']);
    const valueSelect = ref(null);

    const props = defineProps({
        label: { type: String, default: null },
        placeholder: { type: String, default: '--Selecione--' },
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        errors: { default: null },
        modelValue: { default: null },
        onCreate: { default: null },
        type: { type: String, default: 'text' },
        options: { type: Array, default: [] },
        optionLabel: { type: String, default: 'name' },
        optionCustomLabel: { default: null },
        subItem: { default: null },
        optionValue: { type: String, default: 'id' },
    });

    const customLabel = (option) => {
        if (props.optionCustomLabel === null) {
            return option[props.optionLabel];
        }

        return props.optionCustomLabel(option);
    };

    const onSearch = (value) => {
        emit('onSearch', value);
    };

    const onRemove = (selectedOption) => {
        emit('update:modelValue', null);
        emit('onChange', null);
    };

    const updateValue = (selectedOption) => {
        emit('update:modelValue', selectedOption[props.optionValue].toString());
        emit('onChange', selectedOption[props.optionValue].toString());
    };
</script>
