<template>
    <teleport to='#breadcrumb'>
        <ul class='navbar-nav flex-row'>
            <li>
                <div class='page-header'>
                    <nav class='breadcrumb-one' aria-label='breadcrumb'>
                        <ol class='breadcrumb'>
                            <li v-for='(item, index) in data' :class='["breadcrumb-item", {"active": item.active}]'
                                :key='`breadcrumb-item-${index}`'>
                                <router-link v-if='!item.active' :to='item.router'>
                                    {{ item.page }}
                                </router-link>

                                <span v-if='item.active'>{{ item.page }}</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </li>
        </ul>

        <div class='me-3 border border-light rounded px-4 py-1 cursor-pointer' @click.prevent='onDialog'>
            <font-awesome-icon icon='fa-solid fa-building' class='me-2' />
            {{ $store.state.user.contract_name }}
        </div>

        <el-dialog
            v-model='dialog'
            width='500px'
            title='Selecionar contrato'>

            <div class='input-group search-input-group-style input-group mb-4'>
                    <span class='input-group-text'>
                        <font-awesome-icon icon='fa-solid fa-search' class='me-2' />
                    </span>
                <input placeholder='Pesquisar contratos...' class='form-control' v-model='searchContracts' />
            </div>

            <el-scrollbar height='250px'>
                <div class='list-group'>
                    <a href='javascript:void(0);' v-if='contracts.length'
                       class='list-group-item list-group-item-action d-flex justify-content-between'
                       v-for='contract in contracts' :key='contract.id'
                       @click.prevent='setContract(contract.id)'>
                        <div>
                            <font-awesome-icon icon='fa-solid fa-building' class='me-2' />
                            {{ contract.name }}
                        </div>

                        <span class='text-end'>
                            <font-awesome-icon icon='fa-solid fa-angle-right' class='me-2' />
                        </span>
                    </a>

                    <el-empty v-else description='Nenhum contrato foi encontrado.' />
                </div>
            </el-scrollbar>

        </el-dialog>
    </teleport>
</template>

<script setup>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { ElDialog, ElScrollbar, ElEmpty } from 'element-plus';
    import { computed, ref } from 'vue';
    import { useStore } from 'vuex';
    import { handleAxiosError } from '@/helpers';

    const dialog = ref(false);
    const searchContracts = ref('');
    const store = useStore();

    const props = defineProps({
        data: { type: Array, default: [] }
    });

    const contracts = computed(() => {
        const storeContracts = store.state.user.user.contracts;
        if (searchContracts.value.length) {
            return storeContracts.filter(c => c.name.toLowerCase().includes(searchContracts.value.toLowerCase()));
        }

        return storeContracts;
    });

    const onDialog = () => {
        if (store.state.user.user.contracts.length) {
            dialog.value = true;
        }
    };

    const setContract = async (id) => {
        try {
            const { data } = await axios.post(`set-contract`, { id });
            const user = { ...store.state.user.user };

            store.commit('user/setContractName', data.contract_name);
            store.commit('user/setToken', data.token);
            
            user.permissions = data.permissions;
            store.commit('user/setUser', user);

            window.location.href = '/';
        } catch (e) {
            handleAxiosError(e);
        }
    };
</script>

<style scoped>

</style>
